<template>
  <div class="more" :class="{padbot0:!mobileMore}">
    <p class="title">更多产品</p>
    <div class="mob_product" v-show="!mobileMore">
      <div class="mob_card">
        <img :src="utils.setOssUrl(oneProduct.logo)" alt="">
        <p>{{ oneProduct.description }}</p>
      </div>
      <p class="mob_more">
        <span @click="mobileMoreClick">查看更多</span>
        <i @click="mobileMoreClick" class="font_family icon-common_arrow_down"></i>
      </p>
    </div>
    <div class="product_box" v-show="mobileMore">
      <div class="product" v-for="item in moreProduct.productList" :key="item.productId">
        <a :href="`http://${item.domain}?source=more`" target="_blank">
          <p class="pro_imgbox">
            <img class="pro_img" :src="utils.setOssUrl(item.logo)" alt="">
          </p>
          <p class="pro_title">{{ item.productName }}</p>
          <p class="pro_x"></p>
          <p class="pro_desc">{{ item.description }}</p>
          <p class="pro_detail">
            <dj-button>了解详情</dj-button>
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils";

export default {
  name: "more",
  props: {
    moreProduct: {
      type: Object,
      default: () => {
        return {
          productList: []
        };
      }
    }
  },
  data() {
    return {
      mobileMore: false,
      oneProduct: {},
      utils
    };
  },
  created() {
    if (this.moreProduct.productList.length > 0) {
      this.oneProduct = this.moreProduct.productList[0];
    }
  },
  methods: {
    mobileMoreClick() {
      this.mobileMore = true;
    }
  }
};
</script>

<style scoped lang="scss">
.more {
  padding: 60px;
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
  }
  .mob_product {
    margin-top: 24px;
    .mob_card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 140px;
        height: 70px;
      }
      p {
        font-size: 26px;
        color: #333333;
        line-height: 37px;
        width: 467px;
        max-height: 74px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .mob_more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #DBE1EA;
      span {
        font-size: 24px;
        color: #666666;
      }
      i {
        margin-left: 13px;
        font-size: 24px;
        color: #666666;
      }
    }
  }
  .product_box {
    margin-top: 40px;
    .product {
      background-color: #FFFFFF;
      margin-top: 24px;
      padding: 0 40px 48px 40px;
      a{
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      &:first-child {
        margin-top: 0;
      }
      .pro_imgbox {
        text-align: center;
        .pro_img {
          width: 255px;
          height: 128px;
        }
      }
      .pro_title {
        font-size: 28px;
        color: #333333;
        line-height: 40px;
        margin-top: 16px;
        font-weight: bold;
      }
      .pro_x {
        width: 50px;
        height: 8px;
        background: #0064EB;
        margin-top: 16px;
      }
      .pro_desc {
        margin-top: 16px;
        font-size: 24px;
        color: #666666;
        line-height: 33px;
        max-height: 100px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .pro_detail {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
      }
    }
  }
}
.padbot0{
  padding-bottom: 0;
}
@media (min-width: 751px) and (max-width: 960px) {
  .more {
    padding: 60px;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      text-align: center;
      font-weight: bold;
    }
    .mob_product {
      margin-top: 35px;
      .mob_card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 72px;
        img {
          width: 140px;
          height: 70px;
        }
        p {
          font-size: 20px;
          color: #333333;
          line-height: 28px;
          width: 532px;
          max-height: 70px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      .mob_more {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #DBE1EA;
        span {
          font-size: 16px;
          color: #666666;
        }
        i {
          margin-left: 12px;
          font-size: 14px;
          color: #666666;
        }
      }
    }
    .product_box {
      margin-top: 23px;
      display: flex;
      flex-wrap: wrap;
      .product {
        width: 408px;
        background-color: #FFFFFF;
        margin-top: 40px;
        padding: 0 24px 32px 24px;
        margin-left: 24px;
        &:nth-child(2n+1){
          margin-left: 0;
        }
        a{
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        &:first-child {
          margin-top: 40px;
        }
        .pro_imgbox {
          text-align: center;
          .pro_img {
            width: 266px;
            height: 133px;
          }
        }
        .pro_title {
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          margin-top: 16px;
          font-weight: bold;
        }
        .pro_x {
          width: 50px;
          height: 8px;
          background: #0064EB;
          margin-top: 16px;
        }
        .pro_desc {
          margin-top: 18px;
          font-size: 18px;
          color: #666666;
          line-height: 25px;
          max-height: 92px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        .pro_detail {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 22px;
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .more {
    padding: 60px;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      text-align: center;
    }
    .mob_product {
      display: none;
    }
    .product_box {
      display: flex !important;
      margin-top: 40px;
      justify-content: center;
      .product {
        background-color: #FFFFFF;
        margin-top: 0;
        margin-left: 24px;
        padding: 0;
        width: 384px;
        a{
          padding: 0 24px 32px 24px;
          &:hover{
            box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
          }
        }
        &:first-child {
          margin-left: 0;
        }
        .pro_imgbox {
          text-align: center;
          .pro_img {
            width: 266px;
            height: 133px;
          }
        }
        .pro_title {
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          margin-top: 16px;
        }
        .pro_x {
          width: 50px;
          height: 8px;
          background: #0064EB;
          margin-top: 16px;
        }
        .pro_desc {
          margin-top: 18px;
          font-size: 18px;
          color: #666666;
          line-height: 25px;
          max-height: 92px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          height: 74px;
        }
        .pro_detail {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
