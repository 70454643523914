<template>
  <div class="service">
    <p class="title">服务保障</p>
    <div class="service_box">
      <p class="img_box">
        <img :src="utils.setOssUrl(picture)" alt="">
      </p>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils";

export default {
  name: "service",
  props: {
    service: {
      type: Object,
      default: () => {
        return {
          picList: []
        };
      }
    }
  },
  data() {
    return {
      picture: "",
      utils
    };
  },
  created() {
    if (this.service.picList.length > 0) {
      this.picture = this.service.picList[0].picture
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.service {
  width: 100%;
  background-color: #FFFFFF;
  .title{
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    line-height: 56px;
    padding: 60px 0;
    text-align: center;
  }
  .service_box{

  }
  .img_box {
    overflow-x: auto;
    height: 476px;
    padding-bottom: 60px;
    padding-left: 60px;
    img {
      width: 1100px;
      height: 416px;
    }
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .service {
    width: 100%;
    background-color: #FFFFFF;
    .title{
      font-size: 32px;
      font-weight: bold;
      line-height: 45px;
      padding: 60px 0 60px 0;
      text-align: center;
    }
    .service_box{

    }
    .img_box {
      height: auto;
      padding: 0 60px 60px;
      img {
        width: 100%;
        height: 315px;
      }
    }
  }
}
@media (min-width: 961px) {
  .service {
    width: 100%;
    background-color: #FFFFFF;
    .title{
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      line-height: 45px;
      padding: 64px 0 36px 0;
      text-align: center;
    }
    .service_box{
      padding: 0 0 60px 0;
      width: 1200px;
      margin: 0 auto;
    }
    .img_box {
      overflow: hidden;
      width: 100%;
      height: 450px;
      padding-left: 0;
      padding-bottom: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
