<template>
  <router-link to="/product/freeUse" class="free" :style="styleObject">
    <p class="title vishide">现在申请，即可享受超值免费空间</p>
    <dj-button class="btn" type="light" href="/product/freeUse?source=indexModule">免费试用</dj-button>
  </router-link>
</template>

<script>
import utils from "@/utils/utils";

export default {
  name: "free",
  props: {
    freeUse: {
      type: Object,
      default: () => {
        return {
          picList: []
        };
      }
    }
  },
  data() {
    return {
      styleObject: {}
    };
  },
  created() {
    if (this.freeUse.picList.length > 0) {
      this.styleObject = {
        backgroundImage: `url(${utils.setOssUrl(this.freeUse.picList[0].picture)})`
      };
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.free {
  width: 100%;
  height: 281px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assets/images/bg_default.jpg");
  background-position: center;
  background-size: cover;
  .title {
    font-size: 32px;
    color: #FFFFFF;
    line-height: 45px;
  }
  .btn {
    margin-top: 37px;
  }
}
@media (min-width: 750px) and (max-width: 960px) {

}
@media (min-width: 961px) {
  .free {
    .title {
      font-size: 32px;
      color: #FFFFFF;
      line-height: 45px;
    }
    .btn {

    }
  }
}
</style>
