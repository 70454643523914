<template>
  <div class="problem" :class="{bcff:mobileMore,padbot0:!mobileMore}">
    <p class="title">常见问题</p>
    <div class="mob_pro" v-show="!mobileMore">
      <a class="mob_ques" href="">问题1：{{ fristProblem.question }}</a>
      <p class="mob_more">
        <span @click="mobileMoreClick">查看更多</span>
        <i @click="mobileMoreClick" class="font_family icon-common_arrow_down"></i>
      </p>
    </div>
    <div class="pro_box" v-show="mobileMore">
      <ul class="pro_one">
        <li class="pro_li" v-for="(item,index) in oneList" :key="item.problemId">
          <a :href="`/userService/question/details?id=${item.problemId}&moduleType=5`" target="_blank">
            <p class="pro_num">问题{{ index + 1 }}</p>
            <p class="pro_desc">{{ item.question }}</p>
            <i class="font_family icon-common_arrow_right"></i>
          </a>
        </li>
      </ul>
      <ul class="pro_one pro_two" v-if="twoList.length>0">
        <li class="pro_li" v-for="(item,index) in twoList" :key="item.problemId">
          <a :href="`/userService/question/details?id=${item.problemId}&moduleType=5`" target="_blank">
            <p class="pro_num">问题{{ index + 4 }}</p>
            <p class="pro_desc">{{ item.question }}</p>
            <i class="font_family icon-common_arrow_right"></i>
          </a>
        </li>
      </ul>
    </div>
    <p class="more" v-show="mobileMore">
      <dj-button type="dark" href="/userService/question">查看更多</dj-button>
    </p>
  </div>
</template>

<script>
export default {
  name: "problem",
  props: {
    problem: {
      type: Object,
      default: () => {
        return {
          problemList: []
        };
      }
    }
  },
  data() {
    return {
      mobileMore: false,
      oneList: [],
      twoList: [],
      fristProblem: {}
    };
  },
  created() {
    if (this.problem.problemList.length <= 3) {
      this.oneList = this.problem.problemList;
    } else {
      this.oneList = this.problem.problemList.slice(0, 3);
      this.twoList = this.problem.problemList.slice(3, 6);
    }
    if (this.problem.problemList.length > 0) {
      this.fristProblem = this.problem.problemList[0];
    }
  },
  methods: {
    mobileMoreClick() {
      this.mobileMore = true;
    }
  }
};
</script>

<style scoped lang="scss">
.problem {
  padding: 60px;
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
  }
  .mob_pro {
    margin-top: 24px;
    .mob_ques {
      font-size: 26px;
      color: #333333;
      line-height: 37px;
    }
    .mob_more {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #DBE1EA;
      span {
        font-size: 24px;
        color: #666666;
      }
      i {
        font-size: 24px;
        color: #666666;
        margin-left: 13px;
      }
    }
  }
  .pro_box {
    display: flex;
    overflow-x: auto;
    margin-right: -60px;
    margin-top: 60px;
    .pro_one {
      width: 548px;
      .pro_li {
        background: #F5F7FA;
        border-bottom: 1px solid #FFFFFF;
        display: flex;
        width: 548px;
        height: 130px;
        a {
          display: flex;
          width: 100%;
          height: 100%;
          padding: 32px 16px 32px 32px;
          &:active{
            background: #EFF1F5;
          }
        }
        &:last-child {
          border-bottom: none;
        }
        .pro_num {
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          font-weight: bold;
          width: 70px;
        }
        .pro_desc {
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          max-height: 66px;
          margin-left: 24px;
          width: 370px
        }
        i {
          color: #333333;
          font-size: 24px;
          margin-left: 16px;
          display: flex;
          align-items: center;
        }
      }
    }
    .pro_two {
      margin-left: 24px;
    }
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }
}
.bcff {
  background-color: #FFFFFF;
}
.padbot0{
  padding-bottom: 0;
}
@media (min-width: 750px) and (max-width: 960px) {
  .problem {
    padding: 60px;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      text-align: center;
      font-weight: bold;
    }
    .mob_pro {
      margin-top: 24px;
      .mob_ques {
        font-size: 20px;
        color: #333333;
        line-height: 28px;
        padding: 0 72px;
      }
      .mob_more {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        border-bottom: 1px solid #DBE1EA;
        span {
          font-size: 15px;
          color: #666666;
        }
        i {
          font-size: 14px;
          color: #666666;
          margin-left: 12px;
        }
      }
    }
    .pro_box {
      display: flex;
      overflow-x: auto;
      margin-right:0;
      margin-top: 40px;
      .pro_one {
        width: 408px;
        .pro_li {
          background: #F5F7FA;
          border-bottom: 1px solid #FFFFFF;
          display: flex;
          width: 408px;
          height: 114px;
          a {
            display: flex;
            padding: 32px 24px 32px 32px;
            width: 100%;
            height: 100%;
            &:active{
              background: #EFF1F5;
            }
          }
          &:last-child {
            border-bottom: none;
          }
          .pro_num {
            font-size: 20px;
            color: #333333;
            line-height: 28px;
            font-weight: bold;
            width: 60px;
          }
          .pro_desc {
            font-size: 18px;
            color: #333333;
            line-height: 25px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-height: 50px;
            margin-left: 24px;
            width: 230px
          }
          i {
            color: #333333;
            font-size: 24px;
            margin-left: 24px;
            display: flex;
            align-items: center;
          }
        }
      }
      .pro_two {
        margin-left: 24px;
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }
  }
  .bcff {
    background-color: #FFFFFF;
  }
  .padbot0{
    padding-bottom: 0;
  }
}
@media (min-width: 961px) {
  .problem {
    padding: 60px;
    background-color: #FFFFFF;
    .title {
      font-size: 32px;
      line-height: 45px;
    }
    .mob_pro {
      display: none;
    }
    .pro_box {
      display: flex !important;
      justify-content: center;
      overflow-x: hidden;
      margin-right: 0;
      margin-top: 40px;
      .pro_one {
        width: 588px;
        .pro_li {
          padding: 0;
          width: 588px;
          height: 114px;
          a{
            padding: 32px 24px;
            &:hover{
              background: #EFF1F5;
            }
          }
          .pro_num {
            font-size: 20px;
            line-height: 28px;
            width: auto;
          }
          .pro_desc {
            font-size: 18px;
            line-height: 25px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-height: 50px;
            margin-left: 29px;
            width: 380px
          }
          i {
            font-size: 24px;
            margin-left: 54px;
          }
        }
      }
      .pro_two {
        margin-left: 24px;
      }
    }
    .more {
      display: flex !important;
      margin-top: 41px;
    }
  }
}
</style>
