<template>
  <div class="index">
    <!--产品介绍-->
    <Suggest :product="product"/>
    <!--产品价值-->
    <Worth :worth="product.worth"/>
    <div v-for="item in product.displayModule" :key="item">
      <!--免费试用-->
      <Free v-if="item===1" :freeUse="product.freeUse"/>
      <!--服务保障-->
      <Service v-if="item===2" :service="product.serviceGuarantee"/>
      <!--客户案例-->
      <ClientCase v-if="item===3" :customerCase="product.customerCase"/>
      <!--客户反馈-->
      <Feedback v-if="item===8" :feedback="product.customerFeedback"/>
      <!--市场活动-->
      <Activity v-if="item===4" :marketActivity="product.marketActivity"/>
      <!--产业课堂-->
      <Classroom v-if="item===6" :industryClass="product.industryClass"/>
      <!--常见问题-->
      <Problem v-if="item===5" :problem="product.problem"/>
      <!--产品定价-->
      <Price v-if="item===7" :firmPrice="product.firmPrice"/>
      <!--更多产品-->
      <More v-if="item===10" :moreProduct="product.moreProduct"/>
    </div>
  </div>
</template>

<script>
import Suggest from "./components/suggest";
import Worth from "./components/worth";
import Free from "./components/free";
import Service from "./components/service";
import ClientCase from "./components/clientCase";
import Feedback from "./components/feedback";
import Activity from "./components/activity";
import Classroom from "./components/classroom";
import Problem from "./components/problem";
import Price from "./components/price";
import More from "./components/more";
import {getProductDetailById} from "@/api/product.js";
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";

export default {
  name: 'index',
  mixins: [metaInfoMixins],
  components: {Suggest, Worth, Free, Service, ClientCase, Feedback, Activity, Classroom, Problem, Price, More},
  data() {
    return {
      product: {
        displayModule: []
      }
    };
  },
  created() {
    this.getProductDetailByIdFun();
  },
  mounted() {
    // 注册个宏任务，解决mixins里面的title还没有赋值的问题
    setTimeout(() => {
      let params = {
        source: this.$route.query.source,
        title: document.title
      };
      this.$sensors.officialSite_BrowseProduct(params);
    }, 100);
  },
  methods: {
    getProductDetailByIdFun() {
      getProductDetailById().then((res) => {
        if (res.success) {
          this.product = res.data;
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.index {
}
@media (min-width: 750px) and (max-width: 960px) {
}
@media (min-width: 961px) {
  .index {
  }
}
</style>
