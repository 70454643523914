<template>
  <div class="client js_index_client">
    <p class="title">客户案例</p>
    <ul class="client_box">
      <li class="client_one" :class="{client_two:index>0}" v-for="(item,index) in customerCase.caseList"
          :key="item.moduleId">
        <a :href="`/example/details?id=${item.moduleId}&moduleType=3`" target="_blank" class="one_link vishide animate__animated">
          <div class="one_img_box">
            <img class="one_img" :src="utils.setOssUrl(item.picture)" alt="">
          </div>
          <div class="one_bot">
            <p class="one_title">{{ item.title }}</p>
            <p class="one_desc">{{ item.digest }}</p>
            <p class="one_detail">
              <dj-button>了解详情</dj-button>
            </p>
          </div>
        </a>
      </li>
    </ul>
    <p class="more">
      <dj-button type="dark" href="/example/list">查看更多</dj-button>
    </p>
  </div>
</template>

<script>
import $ from "jquery";
import utils from "@/utils/utils"

export default {
  name: "clientCase",
  props: {
    customerCase: {
      type: Object,
      default: () => {
        return {
          caseList: []
        };
      }
    }
  },
  data() {
    return {
      utils
    };
  },
  mounted() {
    this.removeVishide();
    this.$nextTick(() => {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
      window.onresize = () => {
        this.removeVishide();
      };
    });
  },
  methods: {
    handleScroll() {
      utils.handleScroll($(".js_index_client .client_one"), (i, el) => {
        setTimeout(() => {
          el.children(".one_link").removeClass("vishide").addClass("animate__slideInUp").removeClass("animate__slideOutDown");
        }, i * 100);
      }, (i, el) => {
        setTimeout(() => {
          el.children(".one_link").removeClass("animate__slideInUp").addClass("animate__slideOutDown");
        }, i * 100);
      });
    },
    removeVishide() {
      let w = $(window).width();
      if (w < 960) {
        $(".js_index_client .one_link").removeClass("vishide");
      } else {
        $(".js_index_client .one_link").addClass("vishide");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.client {
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    margin-top: 60px;
    text-align: center;
    font-weight: bold;
  }
  .client_box {
    display: flex;
    justify-content: center;
    padding: 60px;
    .client_one {
      width: 100%;
      .one_link {
        background-color: #FFFFFF;
        display: inline-block;
        width: 100%;
        height: 100%;
        .one_img {
          width: 100%;
          height: 354px;
        }
        .one_title {
          font-size: 26px;
          color: #333333;
          line-height: 37px;
          padding: 32px 32px 16px 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .one_desc {
          font-size: 23px;
          color: #666666;
          line-height: 32px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          padding: 0 32px;
        }
        .one_detail {
          display: flex;
          justify-content: center;
          margin: 24px 0 34px;
        }
      }
    }
    .client_two {
      display: none;
    }
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .client {
    .title {
      font-size: 32px;
      line-height: 45px;
      margin-top: 60px;
    }
    .client_box {
      display: flex;
      justify-content: center;
      padding: 40px 60px;
      .client_one {
        width: 100%;
        .one_link {
          background-color: #FFFFFF;
          display: flex;
          width: 100%;
          height: 100%;
          .one_img {
            width: 336px;
            height: 188px;
          }
          .one_bot {
          }
          .one_title {
            font-size: 18px;
            color: #333333;
            line-height: 25px;
            padding: 24px 24px 8px 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .one_desc {
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            padding: 0 24px;
            height: 66px;
          }
          .one_detail {
            display: flex;
            justify-content: flex-start;
            margin: 12px 0 0 24px;
          }
        }
      }
      .client_two {
        display: none;
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 60px;
    }
  }
}
@media (min-width: 961px) {
  .client {
    .title {
      font-size: 32px;
      line-height: 42px;
      margin-top: 62px;
    }
    .client_box {
      display: flex;
      justify-content: center;
      padding: 60px 0;
      .client_one {
        width: 384px;
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
        .one_link {
          background-color: #FFFFFF;
          display: inline-block;
          width: 100%;
          height: 100%;
          .one_img_box{
            overflow: hidden;
          }
          &:hover {
            box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
            .one_img {
              transform: scale(1.1);
            }
            .one_bot {
              .one_title {
                color: #0064EB;
              }
            }
          }
          .one_img {
            width: 100%;
            height: 216px;
            transition: transform .5s;
          }
          .one_title {
            font-size: 18px;
            line-height: 25px;
            padding: 24px 24px 12px 24px;
          }
          .one_desc {
            height: 66px;
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            padding: 0 24px;
          }
          .one_detail {
            display: flex;
            justify-content: center;
            margin: 24px 0;
          }
        }
      }
      .client_two {
        display: block;
      }
    }
    .more {
      margin-bottom: 60px;
    }
  }
}
</style>
