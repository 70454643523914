<template>
  <div class="classroom js_index_classroom" :class="{padbot0:!mobileMore}">
    <p class="title">产业课堂</p>
    <div class="mob_cla" v-show="!mobileMore">
      <div class="mob_top">
        <img :src="utils.setOssUrl(frist.picture)" alt="">
        <p>{{ frist.title }}</p>
      </div>
      <p class="mob_bot">
        <span @click="mobileMoreClick">查看更多</span>
        <i @click="mobileMoreClick" class="font_family icon-common_arrow_down"></i>
      </p>
    </div>
    <ul class="class_box" v-show="mobileMore">
      <li class="class_li" :class="{ml60:industryClass.classList.length===1}" v-for="item in industryClass.classList"
          :key="item.moduleId">
        <a class="class_link vishide animate__animated" :href="`/industryClass/details?id=${item.moduleId}&moduleType=6`" target="_blank">
          <div class="class_img_box">
            <img class="class_img" :src="utils.setOssUrl(item.picture)" alt="">
          </div>
          <p class="class_title">{{ item.title }}</p>
          <p class="class_desc">{{ item.digest }}</p>
          <p class="class_detail">
            <dj-button>了解详情</dj-button>
          </p>
        </a>
      </li>
      <router-link to="/industryClass/list" class="mob_more">
        <span>查看更多</span>
        <i class="font_family icon-class_more"></i>
      </router-link>
    </ul>
    <p class="more">
      <dj-button type="dark" href="/industryClass/list">查看更多</dj-button>
    </p>
  </div>
</template>

<script>
import $ from "jquery";
import utils from "@/utils/utils"
export default {
  name: "classroom",
  props: {
    industryClass: {
      type: Object,
      default: () => {
        return {
          classList: []
        };
      }
    }
  },
  data() {
    return {
      mobileMore: false,
      frist: {},
      utils
    };
  },
  created() {
    if (this.industryClass.classList.length > 0) {
      this.frist = this.industryClass.classList[0];
    }
  },
  mounted() {
    this.removeVishide();
    this.$nextTick(() => {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
      window.onresize = () => {
        this.removeVishide();
      };
    });
  },
  methods: {
    mobileMoreClick() {
      this.mobileMore = true;
    },
    handleScroll() {
      utils.handleScroll($(".js_index_classroom .class_li"), (i, el) => {
        setTimeout(() => {
          el.children(".class_link").removeClass("vishide").addClass("animate__slideInUp").removeClass("animate__slideOutDown");
        }, i * 100);
      }, (i, el) => {
        setTimeout(() => {
          el.children(".class_link").removeClass("animate__slideInUp").addClass("animate__slideOutDown");
        }, i * 100);
      });
    },
    removeVishide() {
      let w = $(window).width();
      if (w < 960) {
        $(".js_index_classroom .class_link").removeClass("vishide");
      } else {
        $(".js_index_classroom .class_link").addClass("vishide");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.classroom {
  padding: 60px 0 60px 0;
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
  }
  .mob_cla {
    margin: 0 60px;
    margin-top: 24px;
    .mob_top {
      display: flex;
      img {
        width: 139px;
        height: 78px;
      }
      p {
        flex: 1;
        margin-left: 24px;
        font-size: 26px;
        color: #333333;
        line-height: 37px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .mob_bot {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #DBE1EA;
      span {
        font-size: 24px;
        color: #666666;
      }
      i {
        margin-left: 13px;
        font-size: 24px;
        color: #666666;
      }
    }
  }
  .class_box {
    display: flex;
    overflow-x: auto;
    margin-top: 41px;
    .class_li {
      width: 548px;
      margin-left: 24px;
      flex-grow: 0;
      flex-shrink: 0;
      a {
        background: #FFFFFF;
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      .class_img {
        width: 100%;
        height: 308px;
      }
      .class_title {
        padding: 32px 24px 18px 24px;
        font-size: 26px;
        color: #252931;
        line-height: 37px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .class_desc {
        padding: 0 24px;
        font-size: 23px;
        color: #666666;
        line-height: 32px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        height: 96px;
      }
      .class_detail {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 37px 0 44px 0;
      }
    }
    .ml60 {
      margin-left: 60px;
    }
    .mob_more {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 36px;
      padding-right: 36px;
      span {
        font-size: 23px;
        color: #666666;
        line-height: 32px;
        width: 25px;
      }
      i {
        color: #666666;
        font-size: 24px;
      }
    }
  }
  .more {
    display: none;
  }
}
.padbot0{
  padding-bottom: 0;
}
@media (min-width: 750px) and (max-width: 960px) {
  .classroom {
    padding: 60px 60px 60px 0;
    overflow: hidden;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      text-align: center;
      font-weight: bold;
      padding-left: 60px;
    }
    .mob_cla {
      margin-right: 0;
      margin-top: 24px;
      .mob_top {
        display: flex;
        padding: 0 72px;
        img {
          width: 139px;
          height: 78px;
        }
        p {
          flex: 1;
          margin-left: 24px;
          font-size: 20px;
          color: #333333;
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .mob_bot {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        border-bottom: 1px solid #DBE1EA;
        span {
          font-size: 16px;
          color: #666666;
        }
        i {
          margin-left: 12px;
          font-size: 14px;
          color: #666666;
        }
      }
    }
    .class_box {
      display: flex;
      overflow-x: auto;
      margin-right: 0;
      margin-top: 41px;
      .class_li {
        width: 408px;
        margin-left: 24px;
        flex-grow: 0;
        flex-shrink: 0;
        &:first-child {
          margin-left: 60px;
        }
        a {
          background: #FFFFFF;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        .class_img {
          width: 100%;
          height: 230px;
        }
        .class_title {
          padding: 16px 24px 10px 24px;
          font-size: 18px;
          color: #252931;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .class_desc {
          padding: 0 24px;
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          height: 66px;
        }
        .class_detail {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px 0 30px 0;
        }
      }
      .ml60 {
        margin-left: 60px;
      }
      .mob_more {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 36px;
        padding-right: 0;
        span {
          font-size: 23px;
          color: #666666;
          line-height: 32px;
          width: 25px;
        }
        i {
          color: #666666;
          font-size: 24px;
        }
      }
    }
    .more {
      display: none;
    }
  }
}
@media (min-width: 961px) {
  .classroom {
    padding: 60px;
    .title {
      font-size: 32px;
      line-height: 45px;
      padding-left: 0;
    }
    .mob_cla {
      display: none;
    }
    .class_box {
      display: flex !important;
      overflow-x: hidden;
      overflow: hidden;
      margin-right: 0;
      justify-content: center;
      padding-bottom: 60px;
      .class_li {
        width: 383px;
        margin-left: 25px;
        a {
          background: #FFFFFF;
          display: inline-block;
          width: 100%;
          height: 100%;
          &:hover {
            box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
            .class_img {
              transform: scale(1.1);
            }
            .class_title {
              color: #0064EB;
            }
          }
        }
        &:first-child {
          margin-left: 0;
        }
        .class_img_box {
          overflow: hidden;
        }
        .class_img {
          width: 100%;
          height: 216px;
          transition: transform .5s;
        }
        .class_title {
          padding: 24px 24px 12px 24px;
          font-size: 18px;
          color: #333333;
          line-height: 25px;
        }
        .class_desc {
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          height: 66px;
        }
        .class_detail {
          padding: 24px 0 20px 0;
        }
      }
      .ml60 {
        margin-left: 0;
      }
      .mob_more {
        display: none;
      }
    }
    .more {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
